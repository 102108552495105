import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import * as dateFns from 'date-fns';
import CollectionStampCardStore from 'stores/next/stampCard';
import StoreStore from 'stores/next/stores';
import page from 'components/next/pages/page/page';
import Spinner from 'components/common/next/spinner';
import { Checkbox } from '../../components/checkbox';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import SearchBox from 'components/next/components/sidebar/searchBox';
import ChainSelector from 'components/next/components/chainSelector';
import StoreSelector from 'components/next/components/storeSelector';
import { ReactComponent as IconDelete } from '@kesko/icons/action/icon-delete.svg';
import { ReactComponent as IconArrowRight } from '@kesko/icons/nav/icon-arrow-right.svg';
import { castDate } from 'utils/helpers';
import { ChainAbbreviations, kRautaChainIds, onninenChainIds } from 'constants/common';
import { CollectionStampCardStatus, CollectionStampCardType } from 'enums/stampCard';
import type { Store, CollectionStampCard } from 'types/next';
import './collectionStampCards.scss';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { createKeskoStampCard, viewKeskoStampCard } from 'components/next/routes';

interface State {
  query: string;
  chainIds: string[];
  storeIds: string[];
  isLoading: boolean;
  message?: string;
  order: 'asc' | 'desc';
  sort?: string;
  searchResult: CollectionStampCard[];
  statuses: { [type: string]: boolean };
  stores: Store[];
}

interface Props {
  stampCardStore: CollectionStampCardStore;
  storeStore: StoreStore;
  keskoStampCard?: boolean;
}

@inject('stampCardStore', 'storeStore')
@observer
class CollectionStampCards extends React.Component<Props, State> {
  debouncedSearchCollectionStampCards: () => void;

  constructor(props) {
    super(props);
    this.state = {
      query: '',
      chainIds: ['3', '4', '5'],
      storeIds: [],
      stores: [],
      sort: 'activeFrom',
      order: 'asc',
      message: null,
      isLoading: true,
      statuses: _.reduce(
        Object.keys(CollectionStampCardStatus),
        (statuses, key) => {
          const status = CollectionStampCardStatus[key as string];
          statuses[status] = true;
          return statuses;
        },
        {},
      ),
      searchResult: [],
    };
    this.debouncedSearchCollectionStampCards = _.debounce(this.searchCollectionStampCards, 500);
  }

  get collectionStampCards() {
    return this.state.searchResult;
  }

  get stampCardStore() {
    return this.props.stampCardStore;
  }

  get storeStore() {
    return this.props.storeStore;
  }

  componentDidMount = async () => {
    await this.searchCollectionStampCards();
    const stores = await this.storeStore.searchStores({});
    this.setState({ stores });
  };

  searchCollectionStampCards = async (limit = 0) => {
    const { stampCardStore, keskoStampCard } = this.props;
    const { chainIds, order, query, sort, statuses, storeIds } = this.state;

    const statusKeys = _.keys(_.pickBy(statuses, Boolean));
    const status: string = statusKeys.length === 1 ? _.first(statusKeys) : null;
    const search = _.omitBy(
      {
        query,
        chainId: chainIds,
        ...(storeIds.length > 0 && { storeId: storeIds }),
        status,
        keskoStampCard,
      },
      _.isNil,
    );

    try {
      const collectionStampCards = await stampCardStore.searchCollectionStampCards({
        payload: search,
        params: {
          limit,
          sort,
          order,
        },
      });
      const searchResult = collectionStampCards.data.result as CollectionStampCard[];

      this.setState({
        searchResult,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  };

  // eslint-disable-next-line
  handleQueryChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === 'query') {
      this.setState({ query: value, isLoading: true });
      this.debouncedSearchCollectionStampCards();
    }
  };

  handleChainChange = (chain: string) => {
    const chainIds = [...this.state.chainIds];
    if (chainIds.includes(chain)) {
      _.pullAt(chainIds, chainIds.indexOf(chain));
    } else {
      chainIds.push(chain);
    }
    this.setState({ chainIds, isLoading: true });
    this.debouncedSearchCollectionStampCards();
  };

  handleStoresChange = (stores: Store[]) => {
    this.setState({ isLoading: true, storeIds: _.map(stores, 'storeId') });
    this.debouncedSearchCollectionStampCards();
  };

  handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { statuses } = this.state;
    const status = e.currentTarget.dataset.collectionStampCardStatus;
    statuses[status] = !statuses[status];
    this.setState({ statuses, isLoading: true });
    this.debouncedSearchCollectionStampCards();
  };

  renderSidebar = () => {
    const { query, chainIds, statuses, stores, storeIds, isLoading } = this.state;
    return (
      <div className="collectionStampCards-sidebar">
        <div className="collectionStampCards-filters">
          <div className="sidebar__title-wrapper">
            <h3 className="sidebar__title">Filters</h3>
            {isLoading && <Spinner addClassName="spinner--unset" />}
          </div>
          <SearchBox
            value={query}
            name="query"
            onChange={this.handleQueryChange}
            placeholder="Search for collection stamp card"
            detail="Search for card title or ID"
          />
          <label>Chain</label>
          <ChainSelector
            chainSelection={chainIds}
            handleChainChange={this.handleChainChange}
            excludeChains={[...kRautaChainIds, ...onninenChainIds]}
          />
          <div className="filter-group">
            <label>Stores</label>
            <StoreSelector
              defaultStoreIds={storeIds}
              stores={stores}
              onChange={this.handleStoresChange}
              detail="Search for store name or business unit ID"
            />
          </div>
          <div className="filter-group">
            <label>Status</label>
            {_.map(Object.keys(CollectionStampCardStatus), (key) => {
              const status: string = CollectionStampCardStatus[key as string];

              return (
                <Checkbox
                  data-collection-stamp-card-status={status}
                  key={`collection-stamp-card-status-${status}`}
                  id={`collection-stamp-card-status-${status}`}
                  label={_.capitalize(status).replace(/_/g, ' ')}
                  checked={statuses[status]}
                  handleClick={this.handleStatusChange}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  renderKeskoStampCardSidebar = () => {
    const { query, isLoading } = this.state;
    const navigate = useNavigate();
    const { chain: chainParam } = useParams();
    return (
      <div className="collection-stamp-card-templates-sidebar">
        <div className="collection-stamp-card-templates-filters">
          <button
            className="add-button sidebar-button"
            onClick={() => navigate(createKeskoStampCard.readyLink(chainParam, 'type'))}
            // onClick={() => navigate(getLink('stampCardEditor', 'create', _.get(template, ['id']), 'type'))}
          >
            <span>Create Stamp Card</span>
            <img src={require('images/add-nega.svg').default} alt="add" />
          </button>
          <div className="sidebar__title-wrapper">
            <h3 className="sidebar__title">Filters</h3>
            {isLoading && <Spinner addClassName="spinner--unset" />}
          </div>
          <SearchBox
            value={query}
            name="query"
            onChange={this.handleQueryChange}
            placeholder="Search for Chain Stamp Card"
            detail="Search for Chain Stamp Card title or ID"
          />
          <label>Chain</label>
          <ChainSelector
            chainSelection={this.state.chainIds}
            handleChainChange={this.handleChainChange}
            excludeChains={[...kRautaChainIds, ...onninenChainIds]}
          />
        </div>
      </div>
    );
  };

  getHeaderClass = (field: string) => {
    const { sort, order } = this.state;

    if (field !== sort) {
      return '';
    }

    return ` sorted ${order}`;
  };

  getCollectionStampCardStatus = (activeFrom: string, activeTo: string) => {
    const today = new Date();

    if (dateFns.isBefore(new Date(activeTo), today)) {
      return 'passed';
    }

    if (dateFns.isAfter(new Date(activeFrom), today)) {
      return 'upcoming';
    }

    return 'active';
  };

  getCollectionStampCardType = (type: CollectionStampCardType) => {
    switch (type) {
      default:
      case CollectionStampCardType.Product:
        return 'Product collecting';
      case CollectionStampCardType.Basket:
        return 'One time purchase';
    }
  };

  delete = async (cardId: string) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this collection stamp card? This action cannot be undone.',
    );

    if (confirmed) {
      await this.stampCardStore.deleteCollectionStampCard(cardId, false);
      this.searchCollectionStampCards();
    }
  };

  renderCollectionStampCardRows = () => {
    // const { stores } = this.state;
    const { keskoStampCard } = this.props;
    const dateFormat = 'd.M.yyyy';

    if (!this.collectionStampCards) {
      return null;
    }

    return (
      <tbody id="collectionStampCard-table-body">
        {this.collectionStampCards.map((c) => (
          <tr key={c.id} className="collectionStampCard-row">
            <td>{c.id}</td>
            <td>{_.get(c, ['uiData', 'uiName', 'fi'])}</td>
            <td
              dangerouslySetInnerHTML={
                !keskoStampCard
                  ? {
                      __html: _.map(_.get(c, ['stores']), (store) => `${store.storeId} ${store.name}`).join('<br />'),
                    }
                  : null
              }
            >
              {keskoStampCard &&
                c.chains?.map((c, i) => (
                  <span className={`chain-name ${ChainAbbreviations[c]}`} key={`chain-${i}`}>
                    {ChainAbbreviations[c]}
                  </span>
                ))}
            </td>
            <td>{c.tosData?.offerNumber}</td>
            {keskoStampCard ? <td>{c.campaignId}</td> : null}
            <td>{this.getCollectionStampCardType(c.type as CollectionStampCardType)}</td>
            <td>{dateFns.format(castDate(c.activeFrom), dateFormat)}</td>
            <td>{dateFns.format(castDate(c.activeTo), dateFormat)}</td>
            <td>{dateFns.format(castDate(c.redeemableTo), dateFormat)}</td>
            <td>{this.getCollectionStampCardStatus(c.activeFrom, c.activeTo)}</td>
            <td>
              {keskoStampCard && (
                <NavLink to={viewKeskoStampCard.readyLink('k-ruoka', c.id)}>
                  <IconArrowRight />
                </NavLink>
              )}
              <button onClick={() => this.delete(_.get(c, ['id']))}>
                <IconDelete />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  render() {
    const { isLoading } = this.state;
    const { keskoStampCard } = this.props;

    return (
      <SidebarWrapper renderSidebar={this.props.keskoStampCard ? this.renderKeskoStampCardSidebar : this.renderSidebar}>
        <div className="collectionStampCards">
          <div className="table-container">
            <table className="styled">
              <thead>
                <tr>
                  <th className={this.getHeaderClass('id')}>Id</th>
                  <th className={this.getHeaderClass('uiData.title.fi')}>Title</th>
                  <th className={this.getHeaderClass('storeId')}>{keskoStampCard ? 'Chains' : 'Stores'}</th>
                  <th className={this.getHeaderClass('offerNumber')}>Offer number</th>
                  {keskoStampCard ? <th className={this.getHeaderClass('campaignId')}>Stamp collecting Id</th> : null}
                  <th className={this.getHeaderClass('type')}>Type</th>
                  <th className={this.getHeaderClass('activeFrom')}>Active from</th>
                  <th className={this.getHeaderClass('activeTo')}>Active to</th>
                  <th className={this.getHeaderClass('redeemableTo')}>Redeemable to</th>

                  <th className={this.getHeaderClass('status')}>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {this.renderCollectionStampCardRows()}
            </table>
          </div>
          {isLoading && <Spinner />}
        </div>
      </SidebarWrapper>
    );
  }
}

export default page(CollectionStampCards);
